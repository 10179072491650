const actions = {

}
const mutations = {

}
const getters = {

}

const state = {
    languages: ['en','de'],
    projectId: '3f97e6cc-ede6-4a60-8582-5638668d45e1'
    //identificationActivity: '312eb927-7191-4609-96f2-7b194cd44126'
};

export default {
  namespaced: true,
  state,
    actions,
    mutations,
    getters
}
