<i18n>
    {
    "en": {

    "page-title": "Privacy Policy & Terms of use",

    "terms-heading": "Terms of Use",

    "terms-list-intro": "In these Terms of Use we define:",
    "terms-list-item-1": "<b>CCCS</b> as the Competence Center Citizen Science (usually referred to as Citizen Science Center Zurich).",
    "terms-list-item-2": "<b>CS Zurich platforms</b> as including the citizenscience.ch platform and all related project platforms (named, for any project “xxx” as “xxx.citizenscience.ch”).",
    "terms-list-item-3": "<b>Users</b> as any visitor on the CS Zurich platforms.",
    "terms-list-item-4": "<b>Project Creators</b> as the users that initiate and manage a project, including defining the project’s content (in any form), providing the necessary data (in any form), and recruiting other users to contribute to their project in the manners allowed by the functionalities of the platforms. Project Creators do not include employees of the CCCS.",
    "terms-list-item-5": "<b>Contributors</b> as the users that voluntarily contribute to projects in the manners allowed by the functionalities of the platforms. Contributors can be anonymous or choose to voluntarily provide some personal information by registering to the CS Zurich platforms.",

    "terms-text-1": "The following Terms of Use apply to all users visiting and/or using in any manner the <b>CS Zurich platforms</b>.",
    "terms-text-2": "<b>Project Creators</b> are wholly responsible for fulfilling obligations related to any project they create and any form of data they upload. They agree to pay all royalties and other amounts owed due to the project content.",
    "terms-text-3": "<b>Contributors</b> are the owner of their own contributions and are responsible for such ownership. If they are not the content owner, they must have permission from the owner prior to contributing.",
    "terms-text-4": "<b>Contributors</b> grant the <b>CCCS</b> a perpetual, royalty-free, non-exclusive license to use, reproduce, modify, and exercise all copyright and publicity rights with respect to their contribution.",
    "terms-text-5": "Contributions to the CS Zurich platforms are available to the Project Creators with a non-exclusive permission to use, distribute, and modify in order to further scientific research.",
    "terms-text-6": "<b>CCCS</b> does not have any ownership rights in the content that <b>Project Creators</b> upload to the <b>CS Zurich platforms</b>. <b>CCCS</b> cannot be held accountable of privacy, legal, or other forms of responsibilities related to data uploaded by <b>Project Creators</b> in the <b>CS Zurich platforms</b>.",
    "terms-text-7": "In the event of a dispute between <b>Project Creators</b> and <b>Contributors</b>, the <b>CCCS</b> is released from claims of every kind and in any way related to such dispute.",
    "terms-text-8": "<b>CCCS</b> does not endorse and has no control over any contribution, cannot guarantee the authenticity of any data which users may provide, and makes no guarantees regarding the performance or fairness of contributors’ activities.",

    "privacy-heading": "Privacy Policy",

    "privacy-text-1": "All data are submitted in accordance with the Swiss Data Protection Act ( DSG ) and the EU General Data Protection Regulation ( GDPR ). All data are stored in servers within Switzerland.",
    "privacy-text-2": "At registration in the platform, <b>users</b> may choose to provide an e-mail address (registration). This e-mail address is not visible, and <b>CCCS</b> will never share email adresses, or personal details, outside of the projects a user has given explicit permission to.",
    "privacy-text-3": "<b>CCCS</b> will use users’ email address only occasionally, to announce new features, new projects, or special events. CCCS may contact users with a newsletter about the progress of the project or the activities of the Center. Users can opt-out of such communications at any time.",
    "privacy-text-4": "<b>CCCS</b> aggregates general statistics on the use of <b>CS Zurich platforms</b> (users and traffic patterns, data about how users respond to various site features, etc.). In order to collect this data, CCCS may use software such as <a href='https://simpleanalytics.com' target='_blank'>Simple Analytics</a> that collects data only associated with the user’s IP address."

    },
    "de": {


    "page-title": "Privacy Policy & Terms of use",

    "terms-heading": "Terms of Use",

    "terms-list-intro": "In these Terms of Use we define:",
    "terms-list-item-1": "<b>CCCS</b> as the Competence Center Citizen Science (usually referred to as Citizen Science Center Zurich).",
    "terms-list-item-2": "<b>CS Zurich platforms</b> as including the citizenscience.ch platform and all related project platforms (named, for any project “xxx” as “xxx.citizenscience.ch”).",
    "terms-list-item-3": "<b>Users</b> as any visitor on the CS Zurich platforms.",
    "terms-list-item-4": "<b>Project Creators</b> as the users that initiate and manage a project, including defining the project’s content (in any form), providing the necessary data (in any form), and recruiting other users to contribute to their project in the manners allowed by the functionalities of the platforms. Project Creators do not include employees of the CCCS.",
    "terms-list-item-5": "<b>Contributors</b> as the users that voluntarily contribute to projects in the manners allowed by the functionalities of the platforms. Contributors can be anonymous or choose to voluntarily provide some personal information by registering to the CS Zurich platforms.",

    "terms-text-1": "The following Terms of Use apply to all users visiting and/or using in any manner the <b>CS Zurich platforms</b>.",
    "terms-text-2": "<b>Project Creators</b> are wholly responsible for fulfilling obligations related to any project they create and any form of data they upload. They agree to pay all royalties and other amounts owed due to the project content.",
    "terms-text-3": "<b>Contributors</b> are the owner of their own contributions and are responsible for such ownership. If they are not the content owner, they must have permission from the owner prior to contributing.",
    "terms-text-4": "<b>Contributors</b> grant the <b>CCCS</b> a perpetual, royalty-free, non-exclusive license to use, reproduce, modify, and exercise all copyright and publicity rights with respect to their contribution.",
    "terms-text-5": "Contributions to the CS Zurich platforms are available to the Project Creators with a non-exclusive permission to use, distribute, and modify in order to further scientific research.",
    "terms-text-6": "<b>CCCS</b> does not have any ownership rights in the content that <b>Project Creators</b> upload to the <b>CS Zurich platforms</b>. <b>CCCS</b> cannot be held accountable of privacy, legal, or other forms of responsibilities related to data uploaded by <b>Project Creators</b> in the <b>CS Zurich platforms</b>.",
    "terms-text-7": "In the event of a dispute between <b>Project Creators</b> and <b>Contributors</b>, the <b>CCCS</b> is released from claims of every kind and in any way related to such dispute.",
    "terms-text-8": "<b>CCCS</b> does not endorse and has no control over any contribution, cannot guarantee the authenticity of any data which users may provide, and makes no guarantees regarding the performance or fairness of contributors’ activities.",

    "privacy-heading": "Privacy Policy",

    "privacy-text-1": "All data are submitted in accordance with the Swiss Data Protection Act ( DSG ) and the EU General Data Protection Regulation ( GDPR ). All data are stored in servers within Switzerland.",
    "privacy-text-2": "At registration in the platform, <b>users</b> may choose to provide an e-mail address (registration). This e-mail address is not visible, and <b>CCCS</b> will never share email adresses, or personal details, outside of the projects a user has given explicit permission to.",
    "privacy-text-3": "<b>CCCS</b> will use users’ email address only occasionally, to announce new features, new projects, or special events. CCCS may contact users with a newsletter about the progress of the project or the activities of the Center. Users can opt-out of such communications at any time.",
    "privacy-text-4": "<b>CCCS</b> aggregates general statistics on the use of <b>CS Zurich platforms</b> (users and traffic patterns, data about how users respond to various site features, etc.). In order to collect this data, CCCS may use software such as <a href='https://simpleanalytics.com' target='_blank'>Simple Analytics</a> that collects data only associated with the user’s IP address."


    }
    }
</i18n>
<template>
    <div>

        <app-content-section>

            <div class="content-wrapper">
                <div class="row row-centered scroll-effect">
                    <div class="col col-large-6">
                        <h2 class="heading centered" id="projects">{{ $t('terms-heading') }}</h2>
                        <p v-html="$t('terms-list-intro')" class="reduced-bottom-margin"></p>
                        <ul>
                            <li v-html="$t('terms-list-item-1')"></li>
                            <li v-html="$t('terms-list-item-2')"></li>
                            <li v-html="$t('terms-list-item-3')"></li>
                            <li v-html="$t('terms-list-item-4')"></li>
                            <li v-html="$t('terms-list-item-5')"></li>
                        </ul>
                        <p v-html="$t('terms-text-1')"></p>
                        <p v-html="$t('terms-text-2')"></p>
                        <p v-html="$t('terms-text-3')"></p>
                        <p v-html="$t('terms-text-4')"></p>
                        <p v-html="$t('terms-text-5')"></p>
                        <p v-html="$t('terms-text-6')"></p>
                        <p v-html="$t('terms-text-7')"></p>
                        <p v-html="$t('terms-text-8')"></p>
                    </div>
                </div>
            </div>

        </app-content-section>

        <app-content-section color="light-greyish">

            <div class="content-wrapper">
                <div class="row row-centered scroll-effect">
                    <div class="col col-large-6">
                        <h2 class="heading centered" id="projects">{{ $t('privacy-heading') }}</h2>
                        <p v-html="$t('privacy-text-1')"></p>
                        <p v-html="$t('privacy-text-2')"></p>
                        <p v-html="$t('privacy-text-3')"></p>
                        <p v-html="$t('privacy-text-4')"></p>
                    </div>
                </div>
            </div>
        </app-content-section>

        <section-newsletter-signup></section-newsletter-signup>

        <app-footer :platform="platform"></app-footer>

    </div>
</template>

<script>

    import ContentSection from '@/components/shared/ContentSection.vue'
    import Footer from '@/components/shared/Footer.vue'
    import SectionNewsletterSignup from "@/components/shared/SectionNewsletterSignup";

    export default {
        name: 'Terms',
        props: {
            platform: {
                type: Boolean,
                default: false
            }
        },
        components: {
            SectionNewsletterSignup,
            'app-content-section': ContentSection,
            'app-footer': Footer
        },
        metaInfo: function() {
            return {
                title: this.$t('page-title'),
                meta: [
                    {
                        property: 'og:title',
                        content: this.$t('page-title'),
                        template: '%s | '+this.$t('site-title')
                    }
                ]
            }
        }
    }

</script>

<style lang="scss">

</style>
